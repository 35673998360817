// 封装本地存储
export default {
    // 存
    set(key, val) {
        localStorage.setItem(key, JSON.stringify(val))
    },
    // 取
    get(key) {
        return JSON.parse(localStorage.getItem(key))
    },
    // 清空
    clear() {
        localStorage.clear()
    }
}
