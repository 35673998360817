import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/Login.vue'
import Layout from '../views/layout/Layout.vue'
import local from '@/utils/local'


Vue.use(VueRouter)

// 静态路由
const staticRoute = [
  // 首页
  {
    path: '/',
    name: 'Login',
    component: Login,
    hide: true
  },
  // 布局
  {
    path: '/home',
    name: 'home',
    component: Layout,
    meta: { title: '后台首页', icon: 'icon-shouye' },
    children: [
      {
        // 后台首页 home 
        path: '/home/screen',
        name: 'screen',
        component: () => import('@/views/home/home.vue'),
        hide: true
      }
    ]
  },

]

// 动态路由
const dynamicRoutes = [
  // 审核管理
  {
    path: '/store',
    name: 'store',
    component: Layout,
    meta: { title: '审核管理', role: ['normal', 'super'], icon: 'icon-dianpu' },
    children: [
      // 处罚规则列表
      {
        path: '/store/PenaltyRules',
        name: 'UserList',
        meta: { title: '处罚规则列表', role: ['normal', 'super'] },
        component: () => import('@/views/store/PenaltyRules.vue'),
      },
      {
        // 商家入驻审核
        path: '/store/StoreAudit',
        name: 'StoreAudit',
        meta: { title: '商家入驻审核', role: ['super'], icon: 'icon-home' },
        component: () => import('@/views/store/StoreAudit'),
      },
      {
        // 广告审核
        path: '/store/GoodsAudit',
        name: 'GoodsAudit',
        meta: { title: '广告审核', role: ['super'] },
        component: () => import('@/views/store/GoodsAudit'),
      },
      {
        // 广告审核
        path: '/store/VideoAudit',
        name: 'VideoAudit',
        meta: { title: '视频审核', role: ['super'] },
        component: () => import('@/views/store/VideoAudit'),
      },
      {
        // 举报与反馈审核
        path: '/store/StoreBlacklist',
        name: 'StoreBlacklist',
        meta: { title: '举报与反馈审核', role: ['super'] },
        component: () => import('@/views/store/StoreBlacklist.vue'),
      },
      // {
      //   // 评论审核
      //   path: '/store/Censorship',
      //   name: 'Censorship',
      //   meta: { title: '评论审核', role: ['super'] },
      //   component: () => import('@/views/store/Censorship.vue'),
      // }
    ],
  },

  // 客服管理
  // {
  //   // path: '/Call',
  //   // name: 'Call',
  //   // component: Layout,
  //   // meta: { title: "客服管理", role: ['normal', 'super'], icon: 'icon-kefu' },
  //   // children: [
  //   //   // 提现审核  
  //   //   {
  //   //     path: '/Call/withdrawAudit',
  //   //     name: 'withdrawAudit',
  //   //     meta: { title: "提现审核", role: ['normal', 'super'] },
  //   //     component: () => import('@/views/Call/withdrawAudit'),
  //   //   },
  //   //   // 举报审核 
  //   //   {
  //   //     path: '/Call/ReportAudit',
  //   //     name: 'ReportAudit',
  //   //     meta: { title: "举报审核", role: ['normal', 'super'] },
  //   //     component: () => import('@/views/Call/ReportAudit'),
  //   //   },
  //     // 用户修改资料审核 
  //     // {
  //     //   path: '/Call/ModifyAudit',
  //     //   name: 'ModifyAudit',
  //     //   meta: { title: '用户修改资料审核', role: ['normal', 'super'] },
  //     //   component: () => import('@/views/Call/ModifyAudit'),
  //     // },
  //     // 客服中心
  //     // {
  //     //   path: '/Call/Call_Center',
  //     //   name: 'Call_Center',
  //     //   meta: { title: '客服中心', role: ['normal', 'super'] },
  //     //   component: () => import('@/views/Call/Call_Center'),
  //     // }
  //   ]
  // },
  // 用户管理 
  {
    path: '/User',
    name: 'User',
    component: Layout,
    meta: { title: '用户管理', role: ['normal', 'super'], icon: 'icon-yonghu' },
    children: [

      // 用户列表
      {
        path: '/User/UserList',
        name: 'UserList',
        meta: { title: '用户列表', role: ['normal', 'super'] },
        component: () => import('@/views/User/UserList'),
      },

      // 用户处罚列表 
      {
        path: '/User/UserBlacklist',
        name: 'UserBlacklist',
        meta: { title: '用户处罚列表', role: ['normal', 'super'] },
        component: () => import('@/views/User/UserBlacklist'),
      },
    ]
  },
  // // 订单管理 
  // {
  //   path: '/Orders',
  //   name: 'Orders',
  //   component: Layout,
  //   meta: { title: '订单管理', role: ['super'], icon: 'icon-dingdan' },
  //   children: [
  //     // 订单列表
  //     {
  //       path: '/Orders/OrdersList',
  //       name: 'OrdersList',
  //       meta: { title: '订单列表', role: ['super'] },
  //       component: () => import('@/views/Orders/OrdersList'),
  //     },
  //     // 投诉列表
  //     {
  //       path: '/Orders/ComplaintsList',
  //       name: 'ComplaintsList',
  //       meta: { title: '投诉列表', role: ['super'] },
  //       component: () => import('@/views/Orders/ComplaintsList'),
  //     }
  //   ]
  // },
  // 系统设置
  {
    path: '/system',
    name: 'system',
    component: Layout,
    meta: { title: "系统设置", role: ['normal', 'super'], icon: 'icon-shouye' },
    children: [
      // 平台公告列表
      {
        path: '/system/AdvertisementList',
        name: 'AdvertisementList',
        meta: { title: "平台公告列表", role: ['normal', 'super'] },
        component: () => import('@/views/system/AdvertisementList'),
      }
      // 系统公告
    ]
  },
  // 财务管理
  {
    path: '/Money',
    name: 'Money',
    component: Layout,
    meta: { title: "财务管理", role: ['normal', 'super'], icon: 'icon-caiwu' },
    children: [
      // 收益列表
      {
        path: '/Money/MoneyList',
        name: 'MoneyList',
        meta: { title: "收益列表", role: ['normal', 'super'] },
        component: () => import('@/views/Money/MoneyList'),
      },
      // 计费规则
      {
        path: '/Money/MoneyRule',
        name: 'MoneyRule',
        meta: { title: "计费规则", role: ['normal', 'super'] },
        component: () => import('@/views/Money/MoneyRule.vue'),
      }
    ]
  },
  // 权限管理
  {
    path: '/Permission',
    name: 'Permission',
    component: Layout,
    meta: { title: "权限管理", role: ['normal', 'super'], icon: 'icon-quanxian' },
    children: [
      // 角色列表
      {
        path: '/Permission/GroupingList',
        name: 'GroupingList',
        meta: { title: "角色列表", role: ['normal', 'super'] },
        component: () => import('@/views/Permission/GroupingList'),
      },
      // 菜单列表
      {
        path: '/Permission/RolePermission',
        name: 'RolePermission',
        meta: { title: "菜单列表", role: ['normal', 'super'] },
        component: () => import('@/views/Permission/RolePermission.vue'),
      }
    ]
  },
]

// 创建路由实例
const router = new VueRouter({
  routes: staticRoute
})


// 动态路由计算
export function calcRoutes() {
  // 获取用户角色
  let role = local.get("role")
  if (!role) {
    return []
  }

  // 计算该角色对应的路由
  let routes = []
  dynamicRoutes.map(route => {
    // 计算一级路由
    if (route.meta.role.includes(role)) {
      // 计算二级路由
      if (route.children && route.children.length) {
        const arr = []
        // 计算二级路由加到route上
        route.children.map(child => {
          if (child.meta.role.includes(role)) {
            arr.push(child)
          }
        })
        route.children = arr
      }
      routes.push(route)
    }
  })


  // 将计算出的路由动态添加到路由实例上
  router.addRoutes(routes)
  return routes
}

// 计算出来的动态路由
const routes = calcRoutes()


// 计算菜单
export function calcMenus(routes) {
  let role = local.get("role")
  if (!role) {
    return []
  }
  const menus = []
  routes = [...staticRoute, ...routes]
  routes.map(route => {
    if (!route.hide) {
      // 二级菜单处理
      const children = []
      if (route.children) {
        route.children.map(child => {
          if (!child.hide) {
            children.push({
              path: child.path,
              title: child.meta.title
            })
          }
        })
      }
      menus.push({
        path: route.path,
        title: route.meta.title,
        icon: route.meta.icon,
        children
      })
    }
  })
  local.set('menus', menus)
  console.log('menu', menus, "计算出来的路由")
  // 3. 将筛选出来的菜单动态渲染
}
calcMenus(routes)


// 路由守卫
// to 去哪
// from 来自哪里
// next
//      next()这个是放行
//      next('/')这个是去哪个页面
router.beforeEach((to, from, next) => {
  const session_token = local.get('session_token')
  const path = to.path
  if (session_token || path === '/') {
    next()
  } else {
    next('/')
  }
})
export default router
