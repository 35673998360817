<template>
  <div class="header">
    <div class="right-top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-for="item in bread" :key="item.title" :to="item.path">{{ item.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="info">
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          欢迎你,{{ info
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="a">个人中心</el-dropdown-item> -->
          <el-dropdown-item command="b">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <!-- <img :src="Url + info.avatar" alt="" class="img" /> -->
    </div>
  </div>
</template>

<script>
import local from "@/utils/local";
import { Url } from "@/config";
import { getUserMessage } from "@/api/user";
// import {bus} from "@/
export default {
  data() {
    return {
      info: "",
      Url,
      bread: [],
    };
  },

  mounted() {
    this.getData();
    console.log(this.$route);
  },

  watch: {

    // 侦听器实现
    $route() {
      this.bread = this.$route.matched
        .filter((v) => v.meta.title)
        .map((v) => {
          return {
            path: v.path,
            title: v.meta.title,
          };
        });
    },
  },
  methods: {
    handleCommand(cmd) {
      if (cmd == "a") {
        this.$router.push("/account/Personal");
      } else {
        this.$message.success("退出成功");
        local.clear();
        this.$router.push("/");
      }
    },
    getData() {
      getUserMessage().then(res => {
        console.log("获取当前角色信息", res);
        this.info = res.data.userName
      })
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .info {
    display: flex;
    align-items: center;
  }
}

.img {
  width: 50px;
  margin-left: 10px;
  border-radius: 25px;
}
</style>