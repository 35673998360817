// 通用工具函数
import axios from "axios"
import { Message } from 'element-ui';
import local from "./local";
// 封装基础地址
axios.defaults.baseURL = 'https://api.gongxiangcesuo.cn'
// 响应时间
axios.defaults.timeout = 2000
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 将token请求 添加到头
    config.headers.session_token = local.get('session_token') || ''
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    const { data } = response;
    const code = data.code;
    // if (code) {
    //     Message.error(Array.isArray(data.errors) ? data.errors[0] : Object.values(data.errors)[0])
    //     return new Promise(() => { })
    // }
    return data;
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
});
// 默认导出
export default axios