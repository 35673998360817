
<template>
  <div class="login">
    <div class="from">
      <h3 class="title">共享厕所后台管理系统</h3>
      <el-form :model="loginForm" :rules="rules" size="medium" ref="form">
        <!-- 账号框 -->
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" placeholder="账号" autocomplete="off">
            <i slot="prefix" class="iconfont icon-user"></i>
          </el-input>
        </el-form-item>

        <!-- 密码框 -->
        <el-form-item prop="password">
          <el-input :type="type" v-model="loginForm.password" placeholder="密码" autocomplete="off">
            <i slot="prefix" class="iconfont icon-lock"></i>
            <i slot="suffix" @click="toggle" class="iconfont"
              :class="type === 'password' ? 'icon-eyeclose' : 'icon-eyeopen'"></i>
          </el-input>
        </el-form-item>
        <!-- 验证码 -->
        <div style="display:flex;justify-content: space-between;">
          <el-form-item prop="number">
            <el-input ref="imageCode " v-model="loginForm.imageCode" placeholder="验证码" name="imageCode" type="text"
              tabindex="3" auto-complete="on" />
          </el-form-item>
          <img :src="imageBase64" style="width:140px;height:50px" @click="getImageCodes" />
        </div>

        <!-- 提交按钮 -->
        <el-form-item>
          <el-button type="primary" @click.enter="submit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login, CodeImg } from "@/api/user";
import local from "@/utils/local";
import { calcRoutes, calcMenus } from "@/router";
export default {
  name: "Login",
  data() {
    return {
      //表单数据
      loginForm: {
        username: '',
        password: '',
        imageCode: '',
        imageToken: ''
      },
      role: "super",
      imageBase64: "",
      //表单验证
      rules: {
        //账号规则
        username: {
          required: true,
          message: "账号不能为空",
          trigger: "blur",
        },
        //密码规则
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ]
      },
      //密码状态
      type: "password",
    };
  },
  mounted() {
    this.code()
  },
  //登录
  methods: {
    code() {
      CodeImg().then((res) => {
        this.imageBase64 = res.data.imageBase64;
        this.loginForm.imageToken = res.data.imageToken;
      });
    },
    // 提交的时
    submit() {
      login(this.loginForm).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success("恭喜你登陆成功");
          local.set("session_token", res.data.sessionToken);
          local.set("role", this.role)
          const routes = calcRoutes();
          calcMenus(routes);
          this.$router.push("/home/screen");
        } else {
          this.loginForm = {
            username: "",
            password: "",
            imageCode: ""
          }
          this.code()
          this.$message.error(res.msg);
        }
      })

    },
    getImageCodes() {
      this.code()
    },
    //切换密码框的状态
    toggle() {
      this.type = this.type === "password" ? "text" : "password";
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2d3a4b;

  .from {
    width: 350px;
    text-align: center;

    .title {
      color: #fff;
      margin-bottom: 20px;
    }

    .el-button {
      width: 100%;
    }

    el-input__inner {
      height: 40px !important;
      background-color: palegreen !important;
    }
  }
}
</style>
