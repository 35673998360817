// 接口函数层 - 用户相关接口
import request from '@/utils/request'
// 登录
export const login = (data) => {
    return request({
        url: '/biz/loginByPwd',
        method: 'post',
        data
    })
}
// 获取图形验证码
export const CodeImg = (data) => {
    return request({
        url: '/biz/getImageCode',
        method: 'post',
        data
    })
}


// 添加用户
export const userRegister = data => {
    return request({
        url: '/biz/register',
        method: 'post',
        data
    })
}
// 获取用户列表
export const userList = data => {
    return request({
        url: '/biz/getAllUserMessage',
        method: 'post',
        data
    })
}

// 获取处罚规则列表
export const getPenaltyConfigurationList = data => {
    return request({
        url: '/biz/penalty/getPenaltyConfigurationList',
        method: 'post',
        data
    })
}
// 获取处罚信息列表
export const getPenaltyList = data => {
    return request({
        url: '/biz/penalty/getPenaltyList',
        method: 'post',
        data
    })
}
// 添加一条处罚配置列表
export const getAddPenaltyConfiguration = data => {
    return request({
        url: '/biz/penalty/addPenaltyConfiguration',
        method: 'post',
        data
    })
}
// 删除一条处罚配置列表 
export const getDeletePenaltyConfiguration = data => {
    return request({
        url: '/biz/penalty/deletePenaltyConfiguration',
        method: 'post',
        data
    })
}
// 修改一条广告配置表
export const getUpdatePenaltyConfiguration = data => {
    return request({
        url: '/biz/penalty/updatePenaltyConfiguration',
        method: 'post',
        data
    })
}
// 编辑账号
export const userEdit = data => {
    return request({
        url: 'user/edit',
        method: 'post',
        data
    })
}
// 删除账号
export const userDel = params => {
    return request({
        url: 'user/del',
        method: 'get',
        params
    })
}

// 批量删除账号
export const userBatch = data => {
    return request({
        url: 'user/batch-del',
        method: 'post',
        data
    })
}

// 个人中心
export const userInfo = data => {
    return request({
        url: 'user/info',
    })
}

// 修改用户头像
export const userImg = data => {
    return request({
        url: 'user/modify-avatar',
        method: 'post',
        data
    })
}

// 获取当前角色信息  
export const getUserMessage = data => {
    return request({
        url: '/biz/getUserMessage',
        method: 'post',
        data
    })
}