<template>
  <div class="left">
    <el-menu :default-active="active" class="el-menu-vertical-demo" background-color="rgb(43, 58, 76)" text-color="#fff"
      active-text-color="#ffd04b" router>
      <template v-for="item in menus">
        <!-- 后台首页 -->
        <!-- <el-menu-item v-if="item.children.length === 0" :key="item.path" :index="item.path">
          <i class="iconfont" :class="item.icon"></i>
          <span slot="title">{{ item.title }}</span>
        </el-menu-item> -->

        <!-- 商品管理 -->
        <el-submenu :key="item.path" :index="item.path" v-if="item.children.length === 0 ? false : true">
          <template slot="title">
            <i class="iconfont " :class="item.icon"></i>
            <span>{{ item.title }}</span>
          </template>
          <el-menu-item v-for="child in item.children" :key="child.componentName" :index="child.componentName">{{
            child.permName
          }}</el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import local from "@/utils/local";
import { getUserMessage } from "@/api/user";
export default {
  name: "VueLeftMenu",

  data() {
    return {
      active: "/home",
      menus: [],
      Array: [],
    };
  },
  watch: {
    $route() {
      this.active = this.$route.path;
    },
  },
  mounted() {

    let path = this.$route.path;
    this.active = this.$route.path;
    getUserMessage().then(res => {
      // 用户信息的路由
      let Array = res.data.permissionList
      // 存储的路由
      let Array2 = local.get("menus");
      // 遍历得到的一级路由
      let Array3 = Array2.map(item => {
        return {
          icon: item.icon,
          path: item.path,
          title: item.title,
          children: []
        }
      })
      // let Array5 = []
      // // 计算后端返回出来一级的路由
      // for (let i = 0; i < Array3.length; i++) {
      //   // console.log("1", Array3[i].title);
      //   for (let j = 0; j < Array.length; j++) {
      //     if (Array3[i].title.indexOf(Array[j].permName) != -1) {
      //       Array5.push(Array3[i])
      //     }
      //   }
      // }
      // console.log("计算后端返回出来一级的路由",Array5);

      // 计算后端返回出来二级的路由
      Array.map(i => {
        Array3.map(j => {
          if (i.componentName.indexOf(j.path) != -1) {
            if (i.componentName === j.path) {
              return
            }
            j.children.push(i)
          }
        })
      });
      // console.log("所有的级菜单", Array3);
      this.menus = Array3
    })
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.left {
  color: #fff;
  width: 300px;
  background-color: rgb(43, 58, 76);
}

.font {
  margin-right: 10px;
}
</style>