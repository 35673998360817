<template>
  <div class="layout">
    <!-- 左侧菜单 -->
    <left-menu></left-menu>
    <div class="main">
      <!-- 头部 -->
      <right-top></right-top>
      <!-- 内容 -->
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "./LeftMenu.vue";
import RightTop from "./RightTop";
export default {
  components: { LeftMenu, RightTop },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.layout {
  display: flex;
  height: 100vh;
  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    // padding: 0 30px;
  }
  .content {
    border: 10px solid rgb(240, 242, 245);
    flex: 1;
    background-color: #eee;
    box-sizing: border-box;
    height: 100%;
  }
}
</style>